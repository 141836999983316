/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Navbar, UnstyledButton, Group, Text, ScrollArea } from '@mantine/core';
import { CiSettings } from "react-icons/ci"
import { AiOutlineClose, AiOutlineLogout} from "react-icons/ai";
import {  useNavigate } from 'react-router-dom';
import { Action_Type, useUserContext } from '../../context/UserContext';
import {FaUsers} from "react-icons/fa"
import {FaUsersRectangle} from "react-icons/fa6"
import {LuHelpingHand} from "react-icons/lu"


const MainLink: React.FC<any> = ({ icon, label, link }) => {
    const { userDispatch} = useUserContext()
    const navigate = useNavigate()

    const logout = () => {
        if(userDispatch)
        userDispatch({
            type: Action_Type.LOGOUT_USER,
        })
        navigate("/login")
    }
    return (
        <UnstyledButton
            onClick={() =>{
                if(link==="#"){
                    logout()
                }else{
                    navigate(link)
                }
                }}
            sx={(theme) => ({
                display: 'block',
                width: '100%',
                padding: 15,
                borderRadius: 10,

                '&:hover': {
                    backgroundColor: 'black'
                },
            })}
        >
            <Group>
                {icon}
                <Text size="sm" className='text-white font-3'>{label}</Text>
            </Group>
        </UnstyledButton>
    );
}

const Sidebar: React.FC<any> = ({ opened, setOpened }) => {
    const{user} = useUserContext()

 
    const sidebarOption = [

        {
            icon: <FaUsers className='text-white' size={25} />,
            label: "Users",
            link: "/admin/dashboard"
        },
        {
            icon: <LuHelpingHand className='text-white' size={25} />,
            label: "Sponsorships",
            link: "/admin/dashboard/sponsorships"
        },
        {
            icon: <FaUsersRectangle className='text-white' size={25} />,
            label: "Room",
            link: "/admin/dashboard/rooms"
        },
        {
            icon: <CiSettings className='text-white' size={25} />,
            label: 'Settings',
            link: "/admin/dashboard/settings"
        },
        {
            icon:<AiOutlineLogout size={25} className='text-white'/>,
            label: 'Logout',
            link:"#"
        },
    ]



  
    return (
        <Navbar p="sm"
            hiddenBreakpoint="sm"
            className='bg-color-4'
            hidden={!opened} width={{ sm: 200, lg: 250 }}
        >
            <ScrollArea className='min-h-[90vh]'>

                <AiOutlineClose
                    onClick={() => setOpened(!opened)}
                    className='block md:hidden ml-2 cursor-pointer'
                    size={30} color='white' />

                <Navbar.Section 
                grow
                   mb={30}
                    className='mt-[100px]'
                >
                    {sidebarOption.map((item, index) => (
                        <MainLink icon={item.icon} label={item.label} key={index}
                        link={item.link}
                        />
                    ))}
                </Navbar.Section>

            </ScrollArea>
        </Navbar>

    );
};

export default Sidebar;
