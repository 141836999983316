import PageWrapper from "../../components/PageWrapper";
import { Flex, TextInput, Text, Select, Textarea } from "@mantine/core";
import { DateTimePicker } from '@mantine/dates';
import { inputStyles, toast } from "../../utils/helpers";
import useAppButton from "../../hooks/useAppButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { createTPC } from "../../services/userService";


const CreateTPC = () => {
    const { AppButton, setLoading } = useAppButton()
    const navigate = useNavigate()
    const [tpc, setTpc] = useState({
        name: "",
        area: "",
        time: "",
        prayer: ""
    })

    const handleInputChnage = ({ target: { name, value } }:
        { target: { name: string, value: string } }) =>
        setTpc({ ...tpc, [name]: value })

    const handleSubmit:
        React.FormEventHandler<HTMLFormElement> = (e) => {
            e.preventDefault()
            const userDate  = new Date(tpc.time)
            const today = new Date();

            if (today > userDate) {
                toast("You can't select past date!").error()
                return;
            }
        
            setLoading(true)
            createTPC(tpc).then(res => {
                console.log(res.data)
                setLoading(false)
                navigate(`/tpc-room/${res.data.data?._id}`, { state: res.data.data })
                toast("Check your mail for TPC details.",res.data.message).success()
            }).catch(err => {
                console.log(err)
                setLoading(false)
                toast(err?.response?.data?.message).error()
            })
        }

        const width = window.innerWidth
    return (
        <PageWrapper showName={true}>
            <form className={`sm:w-full`} onSubmit={handleSubmit}>
                <Flex className="flex-col mx-auto">
                    <Text className="font-1 text-white sm:text-[20px] mx-auto mb-3 sm:w-[500px]">
                        Create A Prayer Cell and ask peers and
                        friends to join in participating in the prayers</Text>
                    <TextInput
                        required
                        onChange={handleInputChnage}
                        name="name"
                        placeholder="Name Of Prayer Cell"
                        className="sm:w-full my-2 bg-color-4"
                        sx={{ input: inputStyles }}
                    />


                    <Flex className="mx-auto my-2 justify-between w-full flex-col sm:flex-row">
                        <Select
                            required
                            onChange={(value: string) => setTpc({ ...tpc, area: value })}
                            placeholder="Catchment Area"
                            sx={{ input: inputStyles }}
                            className="sm:w-[49%] w-full"
                            data={[
                                { label: "School", value: "School" },
                                { label: "Street", value: "Street" },
                                { label: "City", value: "City" },
                                { label: "Town", value: "Town" },
                                { label: "Nation", value: "Nation" },
                            ]}
                        />
                        <DateTimePicker
                            required
                            onChange={(value) => setTpc({ ...tpc, time: `${value}` })}
                            placeholder="Prayer Time"
                            className="sm:w-[49%] sm:mt-0 mt-4 w-full"
                            sx={{ button: inputStyles }}
                        />

                    </Flex>


                    <Textarea
                        required
                        name="prayer"
                        onChange={handleInputChnage}
                        placeholder="Prayer Point"
                        className="w-full my-2"
                        sx={{
                            textarea: { ...inputStyles, height: "120px" }
                        }}
                    />

                    <AppButton txt="Create Prayer Cell" />
                    <br />
                </Flex>
            </form>
        </PageWrapper>
    );
}

export default CreateTPC;