import { Flex, Text } from "@mantine/core"
import PageWrapper from "../../components/PageWrapper";
import { BsUpload } from "react-icons/bs"
import useAppButton from "../../hooks/useAppButton";

const StartTpc = () => {
    const { AppButton } = useAppButton()
    return (
        <PageWrapper showName={true}>
            <Flex className="flex-col mx-auto">
                <Flex className="w-[90%] 
                min-h-[200px]
                 p-4 mx-auto shadow-md 
                 backdrop-blur-md bg-color-1 
                 rounded-[10px] flex-col mb-4">
                    <h5 className="font-3 
                    w-[80%] mx-auto
                    font-normal text-white text-[25px] text-center">Your Teevolution Prayer Cell (TPC) successfully created.</h5>
                    <h5 className="
                    mx-auto
                    my-2
                    text-white font-3 font-bold">Link: <span className="font-medium underline tracking-wide ml-1">https://www.teevotogo.org/tpc/986793939393</span></h5>
                    <Flex className="flex flex-row items-center mx-auto my-2">
                        <BsUpload className="text-color-2 font-3" size={17} />{" "}
                        <Text className="text-color-2 ml-2 font-3 text-[17px]">Invite</Text>
                    </Flex>
                </Flex>

                <AppButton txt="Start Prayer Cell" width="w-[300px]" />
                <br />
                <br />
            </Flex>
        </PageWrapper>

    );
}

export default StartTpc;