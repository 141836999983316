import {
    createStyles,
    Title,
    Button,
    Container,
    Group,
    rem,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(80),
        paddingBottom: rem(80),
    },

    label: {
        textAlign: "center",
        fontWeight: 900,
        fontSize: rem(220),
        lineHeight: 1,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color:
            theme.colorScheme === "dark"
                ? theme.colors.dark[4]
                : theme.colors.gray[2],

        [theme.fn.smallerThan("sm")]: {
            fontSize: rem(120),
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: "center",
        fontWeight: 900,
        fontSize: rem(38),

        [theme.fn.smallerThan("sm")]: {
            fontSize: rem(32),
        },
    },

    description: {
        maxWidth: rem(500),
        margin: "auto",
        marginTop: theme.spacing.xl,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
}));

export default function NotFound() {
    const { classes } = useStyles();
    const navigate = useNavigate();

    return (
        <Container className={classes.root}>
            <div className={classes.label}>404</div>
            <Title className={classes.title}>Page not found</Title>

            {/* <Group position="center">
                <Button
                    size="md"
                    bg={"#1C236A"}
                    type="submit"
                    radius={"md"}
                    mt={10}
                    sx={() => ({
                        fontFamily: `Inter, sans-serif`,
                        fontWeight: 600,
                        color: "white",
                        fontSize: 14,
                        ":hover": {
                            background: "#7F56D9",
                        },
                    })}
                    onClick={() => navigate("/dashboard")}
                >
                    Take me back to dashboard
                </Button>
            </Group> */}
        </Container>
    );
}
