import { Flex, TextInput} from "@mantine/core";
import useAppButton from "../../hooks/useAppButton";
import { inputStyles, toast } from "../../utils/helpers";
import { Action_Type, useUserContext } from "../../context/UserContext";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useEffect, useState } from "react";
import { updateAdminProfile } from "../../services/adminService";

const ProfileSettings = () => {

    const { user } = useUserContext()
    const [info, setInfo] = useState<{name:string,email:string, password?:string}>({
        name: "",
        email: "",
    })

    const { AppButton, setLoading } = useAppButton()

    const { userDispatch } = useUserContext()
    const [showPassword, setShowPassword] = useState(false)
    const handleShowPassword = () => setShowPassword(!showPassword)
    const handleInputChnage = ({ target: { name, value } }: { target: { name: string, value: string } }) => setInfo({ ...info, [name]: value })

    useEffect(() => {
        if (user?.name && user?.email) {
            setInfo({
                name: user?.name,
                email: user?.email
            })
        }
    }, [])

    const handleUpdate = () => {
        setLoading(true)
        updateAdminProfile(info).then(res => {
            toast(res.data.message).success()
            setLoading(false)
            if (userDispatch)
                userDispatch({
                    type: Action_Type.USER_PROFILE,
                    payload: res?.data?.data
                })
                // setInfo(info?.password ?{
                //     name:"",
                //     email:"",
                //     password:""
                // }:{name:"",email:""})
        }).catch(err => {
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }


    return (
        <Flex className="w-full md:w-[60%] flex flex-col md:mx-auto lg:mx-0 justify-around mt-[30px]">
            <TextInput
                required
                value={info.name}
                onChange={handleInputChnage}
                name="name"
                className="font-Poppins w-full"
                size="md"
                placeholder="Name"
                mt={10}
                sx={{ input: inputStyles }}
            />

            <TextInput
                name="email"
                onChange={handleInputChnage}
                required
                value={info.email}
                className="font-Poppins w-full"
                size="md"
                placeholder="Email"
                mt={10}
                sx={{ input: inputStyles }}
            />


            <TextInput
                name="password"
                onChange={handleInputChnage}
                required
                type={showPassword ? "text" : "password"}
                rightSection={!showPassword ? <AiOutlineEye onClick={handleShowPassword} className="cursor-pointer" />
                    : <AiOutlineEyeInvisible onClick={handleShowPassword} className="cursor-pointer" />}
                className="font-Poppins w-full"
                size="md"
                placeholder="Password"
                mt={10}
                sx={{ input: inputStyles }}
            />

            <AppButton txt="Update" handleOnClick={handleUpdate} />
        </Flex>
    );
}

export default ProfileSettings;