import {useEffect} from "react"
import { Flex, Text, TextInput } from "@mantine/core";
import PageWrapper from "../../components/PageWrapper";
import { inputStyles, toast } from "../../utils/helpers";
import useAppButton from "../../hooks/useAppButton";
import { BsMic } from "react-icons/bs"
import { getTpc } from "../../services/userService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const JoinRoom = () => {
    const { AppButton,setLoading } = useAppButton()
    let [roomId,setRoomId] = useState('')
const navigate = useNavigate()

const handleJoinRoom = (roomId:string) => {
    if(!roomId) return;
    setLoading(true)
    getTpc(roomId).then(res => {
        console.log(res.data)
        setLoading(false)
        navigate(`/tpc-room/${roomId}`, {state:res.data?.data})
    }).catch(err => {
        console.log(err)
        setLoading(false)
        toast(err?.response?.data?.message).error()
    })
}

 const roomIdUrl = new URLSearchParams(window.location.search).get("roomId") as string
useEffect(()=>{
    if(!roomIdUrl) return;
    handleJoinRoom(roomIdUrl)
},[roomIdUrl])


  
    return (
        <PageWrapper showName={true}>
            <Flex className="flex-col mx-auto">
                <Text className="font-1 text-white text-[20px] w-[250px] mx-auto mb-3">Enter Prayer Code to Join</Text>

                <TextInput
                onChange={({target:{value}})=>setRoomId(value)} 
                sx={{ input: inputStyles }} className="mx-auto my-2 w-[300px]" placeholder="Enter Code" />
                <AppButton 
                txt={"Join Now"} width="w-[300px]"
                 icon={<BsMic size={20} />} 
                handleOnClick={()=>handleJoinRoom(roomId)}/>
            </Flex>
        </PageWrapper>
    );
}

export default JoinRoom;