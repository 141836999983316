import { Flex, TextInput } from "@mantine/core";
import PageWrapper from "../components/PageWrapper";
import { inputStyles, toast } from "../utils/helpers";
import useAppButton from "../hooks/useAppButton";
import { Action_Type, useUserContext } from "../context/UserContext";
import { login } from "../services/userService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"


const Login = () => {
    const redirectUrl = new URLSearchParams(window.location.search).get("redirectUrl")
    const { setLoading, AppButton } = useAppButton()
    const [showPassword, setShowPassword] = useState(false)
    const [user, setUser] = useState({
        email: "",
        password: "",
    })
    const { userDispatch } = useUserContext()
    const navigate = useNavigate()
    const handleInputChnage = ({ target: { name, value } }: { target: { name: string, value: string } }) => setUser({ ...user, [name]: value })
    const handleShowPassword = () => setShowPassword(!showPassword)


    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        setLoading(true)
        login(user).then((res) => {
            console.log(res.data)
            setLoading(false)
            toast(res.data.message).success()
            setUser({
                email: "",
                password: "",
            })
            if (userDispatch)
                userDispatch({
                    type: Action_Type.USER_PROFILE,
                    payload: res.data.data,
                })
                if(res.data?.data?.role==="admin"){
                    navigate("/admin/dashboard")
                }else{
                    navigate(redirectUrl ? redirectUrl : "/")
                }
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }
    return (
        <PageWrapper title="Login to your account">
            <Flex className="flex-col mx-auto">
                <form onSubmit={handleSubmit}>
                    <TextInput
                        className="my-2 mx-auto "
                        placeholder="Email"
                        name="email"
                        onChange={handleInputChnage}
                        sx={{ input: inputStyles, width: 300 }} />
                    {/* <TextInput
                        className="my-2 mx-auto"
                        placeholder="Password"
                        name="password"
                        onChange={handleInputChnage}
                        sx={{ input: inputStyles, width: 300 }} /> */}
                    <TextInput
                        name="password"
                        onChange={handleInputChnage}
                        rightSection={!showPassword ? <AiOutlineEye onClick={handleShowPassword} className="cursor-pointer" />
                            : <AiOutlineEyeInvisible onClick={handleShowPassword} className="cursor-pointer" />}
                        type={showPassword ? "text" : "password"}
                        className="my-2 mx-auto "
                        placeholder="Password"
                        required
                        sx={{ input: inputStyles, width: 300 }} />

                    <AppButton txt="Login" width="w-[300px]" btnColor="bg-color-3 text-white" btnType={2} />
                </form>
                <div className="text-center">
                    <div>
                        <span
                            className="cursor-pointer"
                            onClick={() => navigate(redirectUrl?`/register?redirectUrl=${redirectUrl}`:'/register')}
                            style={{ color: "white" }}
                        >
                            <span>Don't have an account? </span>
                            <span style={{ textDecoration: "underline" }}>Register Now</span>
                        </span>
                    </div>
                </div>
            </Flex>
        </PageWrapper>
    );
}

export default Login;