import { Button, Card, Flex, ScrollArea, Skeleton, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { getRooms } from "../../services/adminService";
import { toast } from "../../utils/helpers";
import usePagination from "../../hooks/usePagination";
import useCSVExport from "../../hooks/useCSVExport";
import { CiExport } from "react-icons/ci";

const Rooms = () => {
    const [rooms, setRooms] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // if(rooms.length > 0) return;
        setLoading(true)
        getRooms().then(res => {
            setLoading(false)
            setRooms(res.data?.data)
            toast(res.data?.message).success()
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }, [])

    const { PaginationBtn, data } = usePagination(rooms)

    const rows = data()?.map((room: any) => {
        const date = room?.time ? new Date(room?.time).toLocaleTimeString('en-GB', { day: '2-digit', month: "2-digit", year: "numeric" }).split(",")[0].trim() : "N/A"
        return (
            <tr key={room?._id}>
                <td>{room?._id}</td>
                <td>{room?.name}</td>
                <td>{room?.area}</td>
                <td>{date.length === 10 ? date : "N/A"}</td>
            </tr>)
    });

    const excelHeader = [
        { label: "Room ID", key: "_id" },
        { label: "Room Name", key: "name" },
        { label: "Area", key: "area" },
        { label: "Time", key: "time" },
    ]
    const { CSVBtn } = useCSVExport(rooms, excelHeader)
    return (
        <>
            <Flex className="flex flex-row items-center justify-between">
                <h1 className="my-1 font-3">Rooms</h1>
                <Button
                    className="bg-color-4"
                    leftIcon={<CiExport size={15} />}>
                    <CSVBtn />
                </Button>
            </Flex>
            <Skeleton visible={loading}>
                <Card
                    padding={20}>
                    <ScrollArea>
                        <Table
                            highlightOnHover
                            verticalSpacing={'xs'}
                            horizontalSpacing={'md'}>
                            <thead>
                                <tr>
                                    <th>Room ID</th>
                                    <th>Name</th>
                                    <th>Area</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </ScrollArea>

                    <PaginationBtn
                    />
                </Card>
            </Skeleton>


        </>
    );
}

export default Rooms;