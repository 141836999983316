import { Outlet } from 'react-router-dom';

const PrivateLayout = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

export default PrivateLayout;