import useAppButton from "../hooks/useAppButton";
import PageWrapper from "../components/PageWrapper";
import { Flex } from "@mantine/core";
import { BsMic } from "react-icons/bs"
import { useNavigate } from "react-router-dom";

const Home = () => {
    const { AppButton } = useAppButton()
    const navigate = useNavigate()
    const handleNavigation = (path: string) => navigate(path)
    return (
        <PageWrapper showName={true}>
            <Flex className="w-full" direction={'column'}>
                <Flex className="w-[80%] min-h-[200px] p-4 mx-auto shadow-md backdrop-blur-md bg-color-1 rounded-[10px]">
                    <h5 className="font-2 font-normal text-white text-[20px] text-center">The TeeVolution Prayer Cell is created to engage
                        and encourage teens to create <span className="font-2 text-[20px]">PRAYER CELLS</span> to
                        pray about the impact of the TeeVolution campaign
                        in your schools city, town or nation, any where teenagers just like yourselves can be found within your catchment.</h5>
                </Flex>
                {/* <Text className="text-white text-[50px]">data</Text> */}
                <br />
                <AppButton
                    txt="Join A Prayer Cell"
                    icon={<BsMic size={20} />}
                    handleOnClick={() => navigate("/join-tpc", {state:{from :"/"}})} />
                <AppButton btnType={2} txt="Create A Prayer Cell"
                    btnColor="bg-color-3 text-white" handleOnClick={() => navigate("/create-tpc")} />
            </Flex>
        </PageWrapper>
    );
}

export default Home;