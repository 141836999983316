import axios from "./axiosInstance";

export const getRooms = async ()=> await axios.get("/admin/rooms");

export const getSponsorships = async ()=>await axios.get("/admin/sponsorships")

export const getUsers=async()=>await axios.get("/admin/users");

export const updateAdminProfile = async(data:any) => await axios.patch("/admin/update-profile", data)

export const createAdminUser = async (user:any) => await axios.post("/admin/create-admin",user)