import { Navigate, useLocation } from 'react-router-dom';
import { useUserContext, userToken } from '../../context/UserContext';
// import { dashboardRoutes } from '../../utils/routes';

const AdminRoutes = ({ children }: { children: any }) => {
    const token = userToken()
    const { user } = useUserContext()
    const{pathname,search} = useLocation()



    // const redirectUrl = () => {
    //     if (location.pathname === dashboardRoutes.settings) {
    //         const settingType = new URLSearchParams(window.location.search).get("type")
    //         if (settingType) {
    //             return `${location.pathname}?type=${settingType}`
    //         } else return location.pathname
    //     }
    //     return location.pathname
    // }

    const redirectUrl = () => {
        if(pathname === "/join-tpc"){
const roomId = new URLSearchParams(search).get("roomId")
if (roomId) {
                return `${pathname}?roomId=${roomId}`
            } else return pathname
        }
        return pathname
    } 

    if (!token || 
        (user?.role &&
        user?.role !== "admin")
        // || !user?.name
    ) {
        // return <Navigate to={`/login?redirectUrl=${redirectUrl()}`} />
        return <Navigate to={`/login`} />
    }
    else if (user?.name &&  user?.role === "admin"){
     return children
    } 
}

export default AdminRoutes;
