import axios from "./axiosInstance";

type User = {
    name?: string;
    email: string
    password: string
    country?: string
}


export const register = async (user: User) => await axios.post('/users', user);

export const login = async (user: User) => await axios.post('/users/login', user)

export const getUserProfile = async () => await axios.get("/users/profile");

export const createTPC = async (tpc: any) => await axios.post("/users/tpc", tpc)

export const getTpc = async (roomId: string) => await axios.get(`/users/tpc/${roomId}`)

export const getAccessToken = async () => await axios.get("/users/access-token")

export const makeSponsorship = async (data:any) => await axios.post("/users/sponsorship",data)