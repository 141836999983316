import { notifications } from "@mantine/notifications";
export const inputStyles = {
    backgroundColor: '#EFF1F9',
    // color:"black",
    // width: "300px",
    height: "45px",
    borderRadius: 10,
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    // ':focus': {
    //     border: "1px solid #F39B17"
    // }
}

export const toast = (message: string, title?: string) => {
    return {
        success: () => notifications.show({
            color: "green",
            title: title ? title : 'Success',
            message,
            withBorder: true,
            // autoClose: 5000
        }),
        error: () => notifications.show({
            color: "red",
            title: title ? title : 'Failed',
            message,
            withBorder: true,
        })
    }
}

export const imageUrl = "https://res.cloudinary.com/dyxk7wdj7/image/upload/v1680467188/christian-buehner-DItYlc26zVI-unsplash_btgj0p.jpg"