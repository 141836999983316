import { Button, Card, Flex, ScrollArea, Skeleton, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { getUsers } from "../../services/adminService";
import { toast } from "../../utils/helpers";
import usePagination from "../../hooks/usePagination";
import { CiExport } from "react-icons/ci";
import useCSVExport from "../../hooks/useCSVExport";

const Dashboard = () => {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // if(users?.length >0) return;
        setLoading(true)
        getUsers().then(res => {
            setLoading(false)
            setUsers(res.data?.data)
            toast(res.data?.message).success()
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }, [])


    const { PaginationBtn, data } = usePagination(users)

    const rows = data()?.map((user: any) => (
        <tr key={user?.id}>
            <td>{user?.name}</td>
            <td>{user?.email}</td>
            <td>{user?.country}</td>
            <td>{user?.state}</td>
        </tr>
    ));

    const excelHeader = [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Country", key: "country" },
        { label: "State", key: "state" },
    ]

    const {CSVBtn} = useCSVExport(users,excelHeader)

    return (
        <>
        <Flex className="flex flex-row items-center justify-between">
            <h1 className="my-1 font-3">Users</h1>
            <Button 
            className="bg-color-4"
            leftIcon={<CiExport size={15}/>}>
                 <CSVBtn/>
                </Button>
        </Flex>
            <Skeleton visible={loading}>
                <Card
                    padding={20}>
                    <ScrollArea>
                        <Table
                            highlightOnHover
                            verticalSpacing={'xs'}
                            horizontalSpacing={'md'}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Country</th>
                                    <th>State</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </ScrollArea>
                    <PaginationBtn />
                </Card>
            </Skeleton>

        </>
    );
}

export default Dashboard;