import { Flex, Select, Text, TextInput } from "@mantine/core";
import PageWrapper from "../components/PageWrapper";
import { inputStyles, toast } from "../utils/helpers";
import useAppButton from "../hooks/useAppButton";
import { useFlutterwave,closePaymentModal  } from "flutterwave-react-v3";
import { useState } from "react";
import { FlutterwaveConfig } from "flutterwave-react-v3/dist/types";
import { makeSponsorship } from "../services/userService";


const Sponsorship = () => {
    const { AppButton, setLoading } = useAppButton()
    const [amount,setAmount] = useState(0)
    const [duration,setDuration] = useState<string | null>("")

    const config:FlutterwaveConfig = {
    public_key: 'FLWPUBK-363c6c6e2fdfa1b7286215b0b82f3167-X',
    // public_key: 'FLWPUBK_TEST-39d58b3f8c70e0caece5d7e8b9e2fc9f-X',
    tx_ref: `${Date.now()}`,
    amount,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: "tpc@teevotogo.org",
       phone_number: "+2347012345678",
      name: "John Doe",
    },
    customizations: {
        title: 'TPC',
        description: 'Sponsorship',
        logo: 'https://tpc.penciledge.net/static/media/logo.df2989e1203585a02ce2.png',
      },
  };


  const handlePayment = () => {
    setLoading(true)
    handleFlutterPayment({
        callback: (response) => {
            // setLoading(false)
            const {flw_ref,transaction_id} = response;
           console.log(response);
           makeSponsorship({
            duration,
            amount,
            flw_ref,
            transaction_id
           }).then(res=>{
            setLoading(false)
            toast(res.data.message).success()
           }).catch(err=>{
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
           })
            closePaymentModal() // this will close the modal programmatically
        },
        onClose: () => {
            setLoading(false)
        },
      })
  }
  const handleFlutterPayment = useFlutterwave(config);
    return (
        <PageWrapper title="Sponsor Teevo Distribution">
            <Flex className="flex-col mx-auto">
                <Text className="font-3 text-white text-center text-[20px] mx-auto mb-3 w-[500px]">
                    TeeVo Quantity Sponsorship</Text>

                <Select 
                onChange={(value)=>setDuration(value)}
             placeholder="Select Duration"
                sx={{ input: inputStyles, width: 300 }}
                    data={[
                        { label: "Daily", value: "Daily" },
                        {label:"Weekly", value:"Weekly"},
                        {label:"Monthly", value:"Monthly"},
                        {label:"Yearly", value:"Yearly"},
                    ]}
                    className="my-2 mx-auto"
                />
                <TextInput
                placeholder="Amount"
                onChange={({target:{value}})=>setAmount(+value)}
                    className="my-2 mx-auto"
                    sx={{ input: inputStyles, width: 300 }} />

                <AppButton 
                
                txt="Sponsor Now" width="w-[300px]"  handleOnClick={handlePayment}/>
            </Flex>
        </PageWrapper>
    );
}

export default Sponsorship;