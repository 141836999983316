import { Button } from "@mantine/core";
import { ReactNode, useState } from "react"


const useAppButton = () => {

    const [loading, setLoading] = useState(false)

    const AppButton = ({
        btnType = 1,
        txt,
        btnColor = "bg-color-2",
        width = "w-[350px]",
        icon,
        handleOnClick
    }:
        { txt: string, width?: string, btnColor?: string, icon?: ReactNode, handleOnClick?: () => void, btnType?: number }) => {
        return (
            <div className="flex w-[200px] items-center mx-auto">
                <Button
                    loaderPosition="left"
                    onClick={handleOnClick}
                    loading={loading}
                    type="submit"
                    leftIcon={icon}
                    className={`${btnColor} ${btnType === 1 ? "hover:bg-color-2" : "hover:bg-color-3"} mx-auto h-[40px] my-2 w-full text-color-4 font-1`} >
                    {txt}
                </Button >
            </div>

        )
    }

    return { AppButton, setLoading, loading }


}

export default useAppButton