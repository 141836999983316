import { Flex, TextInput } from "@mantine/core";
import useAppButton from "../../hooks/useAppButton";
import { inputStyles, toast } from "../../utils/helpers";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { createAdminUser } from "../../services/adminService";
import { saveAs } from 'file-saver';

const CreateAdmin = () => {

    const [info, setInfo] = useState({
        name: "",
        email: "",
        password: ""
    })

    const handleInputChnage = ({ target: { name, value } }: { target: { name: string, value: string } }) => setInfo({ ...info, [name]: value })

    const { AppButton, setLoading, loading } = useAppButton()
    const [showPassword, setShowPassword] = useState(false)
    const handleShowPassword = () => setShowPassword(!showPassword)


    const handleCreation = () => {
        setLoading(true)
        createAdminUser(info).then(res => {
            toast(res.data.message).success()
            setLoading(false)
            const {_id,name,email} = res.data?.data;
            const data = new Blob([`name:${name}\nemail:${email}\npassword:${info.password}`],{type:"text/plain;charset=utf-8"})
            saveAs(data,`${name}-${_id}-admin-details.txt`)
            setInfo({
                name:"",
                email:"",
                password:""
            })
        }).catch(err => {
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }


    return (
        <Flex className="w-full md:w-[60%] flex flex-col md:mx-auto lg:mx-0 justify-around mt-[30px]">
            <TextInput
                disabled={loading}
                name="name"
                required
                value={info.name}
                onChange={handleInputChnage}
                className="font-Poppins w-full"
                size="md"
                placeholder="Name"
                mt={10}
                sx={{ input: inputStyles }}
            />

            <TextInput
                disabled={loading}
                name="email"
                required
                onChange={handleInputChnage}
                value={info.email}
                className="font-Poppins w-full"
                size="md"
                placeholder="Email"
                mt={10}
                sx={{ input: inputStyles }}
            />


            <TextInput
                disabled={loading}
                onChange={handleInputChnage}
                name="password"
                required
                type={showPassword ? "text" : "password"}
                rightSection={!showPassword ? <AiOutlineEye onClick={handleShowPassword} className="cursor-pointer" />
                    : <AiOutlineEyeInvisible onClick={handleShowPassword} className="cursor-pointer" />}
                className="font-Poppins w-full"
                size="md"
                placeholder="Password"
                mt={10}
                sx={{ input: inputStyles }}
            />

            <AppButton txt="Create" handleOnClick={handleCreation} />
        </Flex>
    );
}

export default CreateAdmin;