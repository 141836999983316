import { Card, Tabs} from "@mantine/core";
import ProfileSettings from "../../components/admin/ProfileSettings";
import CreateAdmin from "../../components/admin/CreateAdmin";


const Settings = () => {
   
    return (
        <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-[7.5px] 2xl:gap-[7.5]">
            <Card
                padding={20}
                mih={400}
                className="col-span-12 rounded-xl">
                <Tabs defaultValue="account" color="#000">
                    <Tabs.List>
                        <Tabs.Tab value="account">Account</Tabs.Tab>
                        <Tabs.Tab value="purchase">Create Admin</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="account">
                       <ProfileSettings/>
                    </Tabs.Panel>

                    <Tabs.Panel value="purchase" >
                  <CreateAdmin/>
                    </Tabs.Panel>
                </Tabs>
            </Card>
        </div>
    );
}

export default Settings;