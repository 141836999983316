

const Test = () => {
  return (
    <iframe 
    title="Frst meeting"
    src="https://tpc.whereby.com/meeting-oneca0c1505-0222-4d4a-b6e6-65b5975779f6?cameraAccess=off" 
    // src="https://tpc.whereby.com/all-hands-on-deck311f8be5-01d9-4189-a3fc-42282e2a58f3"
    allow="camera; microphone; fullscreen; speaker; display-capture" 
    style={{height: '500px',width: '100%'}}></iframe>
    );
}
 
export default Test;