import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import Home from "./pages/Home";
import JoinRoom from "./pages/tpc/JoinRoom";
import CreateTPC from "./pages/tpc/CreateTpc";
import StartTpc from "./pages/tpc/StartTpc";
import TpcRoom from "./pages/tpc/TpcRoom";
import Sponsorship from "./pages/Sponsorship";
import Login from "./pages/Login";
import Register from "./pages/Register";
import PrivateRoutes from "./components/routes/PrivateRoutes";
import PrivateLayout from "./components/PrivateLayout";
import PublicRoutes from "./components/routes/PublicRoutes";
import NotFound from "./pages/NotFound";
import Test from "./pages/test";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import Dashboard from "./pages/admin-dashboard";
import Rooms from "./pages/admin-dashboard/rooms";
import Settings from "./pages/admin-dashboard/settings";
import SponsorshipsPage from "./pages/admin-dashboard/sponsorships";
import AdminRoutes from "./components/routes/AdminRoutes";

const App = () => {
  return (
    <>
      <MantineProvider withNormalizeCSS withGlobalStyles>
        <Notifications position="top-right" zIndex={2077} />
        <BrowserRouter>
          <Routes>
            <Route element={
              <PrivateRoutes>
                <PrivateLayout />
              </PrivateRoutes>
            }>
              <Route path="/" element={<Home />} />
              <Route path="/join-tpc" element={<JoinRoom />} />
              <Route path="/create-tpc" element={<CreateTPC />} />
              <Route path="/start-tpc" element={<StartTpc />} />
              <Route path="/tpc-room/:roomId" element={<TpcRoom />} />
            </Route>


            <Route element={
              <AdminRoutes>
                <DashboardLayout />
                </AdminRoutes>
            }>
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/dashboard/rooms" element={<Rooms/>}/>
              <Route path="/admin/dashboard/settings" element={<Settings/>}/>
              <Route path="/admin/dashboard/sponsorships" element={<SponsorshipsPage/>}/>
            </Route>

            <Route path="/sponsorship" element={
              <Sponsorship />
            } />
            <Route path="/login" element={
              <PublicRoutes>
                <Login />
              </PublicRoutes>
            } />
            <Route path="/register" element={
              <PublicRoutes>
                <Register />
              </PublicRoutes>
            } />

            <Route path="*" element={<NotFound />} />

            <Route path="/test" element={<Test />} />
          </Routes>
        </BrowserRouter>
      </MantineProvider >
    </>
  );
}

export default App;