import { Button, Card, Flex, ScrollArea, Skeleton, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { getSponsorships } from "../../services/adminService";
import { toast } from "../../utils/helpers";
import usePagination from "../../hooks/usePagination";
import useCSVExport from "../../hooks/useCSVExport";
import { CiExport } from "react-icons/ci";

const SponsorshipsPage = () => {
    const [rooms, setRooms] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // if(rooms.length > 0) return;
        setLoading(true)
        getSponsorships().then(res => {
            setLoading(false)
            setRooms(res.data?.data)
            toast(res.data?.message).success()
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }, [])

    const { PaginationBtn, data } = usePagination(rooms)

    const rows = data()?.map((sponsorship: any) => {
        return (
            <tr key={sponsorship?._id}>
                <td>{sponsorship?.transaction_id}</td>
                <td>{sponsorship?.duration}</td>
                <td>NGN {sponsorship?.amount}</td>
                <td>{sponsorship?.flw_ref}</td>
            </tr>)
    });

    const excelHeader = [
        { label: "Transaction ID", key: "transaction_id" },
        { label: "Duration", key: "duration" },
        { label: "Amount", key: "amount" },
        { label: "Pay Ref", key: "flw_ref" },
    ]
    const { CSVBtn } = useCSVExport(rooms, excelHeader)

    return (
        <>
            <Flex className="flex flex-row items-center justify-between">
                <h1 className="my-1 font-3">Sponsorships</h1>
                <Button
                    className="bg-color-4"
                    leftIcon={<CiExport size={15} />}>
                    <CSVBtn />
                </Button>
            </Flex>
            <Skeleton visible={loading}>
                <Card
                    padding={20}>
                    <ScrollArea>
                        <Table
                            highlightOnHover
                            verticalSpacing={'xs'}
                            horizontalSpacing={'md'}>
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Duration</th>
                                    <th>Amount</th>
                                    <th>Pay Ref.</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </ScrollArea>

                    <PaginationBtn
                    />
                </Card>
            </Skeleton>


        </>
    );
}

export default SponsorshipsPage;