/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text } from "@mantine/core";
import PageWrapper from "../../components/PageWrapper";
import { BsChat, BsMicMute } from "react-icons/bs";
import { IoExit } from "react-icons/io5"
import useAppButton from "../../hooks/useAppButton";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAccessToken, getTpc } from "../../services/userService";
import { toast } from "../../utils/helpers";
import { AudioConference, LiveKitRoom } from '@livekit/components-react';

const TpcRoom = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    const [tpc, setTpc] = useState({
        name: "", time: "", area: "", prayer: "", serverUrl: "", accessToken: ""
    })
    const { roomId } = useParams()

    console.log(state);

    useEffect(() => {
        if (tpc.name) return;
        if (state?.name && state?._id) {
            setTpc(state)
        } else {
            getTpc(`${roomId}`).then(res => {
                setTpc(res.data.data)
                toast(res.data.message).success()
            }).catch(err => {
                console.log(err)
                // navigate("*")
                toast(err?.response?.data?.message).error()
            })
        }
    }, [tpc.name])

    return (
        <PageWrapper showName={true} title={`${tpc.name}, TCP Prayer Room`} icon={true}>
            <Flex className="w-[90%] 
                min-h-[200px]
               p-10 mx-auto shadow-md 
                 backdrop-blur-md bg-color-1 
                 rounded-[10px] flex-col mb-4">
                <h3 className="text-center text-color-2 my-2 font-2 text-[33px]">Prayer Point</h3>
                {/* /<h5 className="font-2 text-[22px] text-white font-medium">PRAY FOR THE ORGANIZATION AND IMPACT OF THE UPCOMING HEALING STREAMS LIVE HEALING SERVICES</h5> */}
                <p className="font-2 text-white text-[20px]">
                    {tpc.prayer}
                    {/* Habakkuk 2:2 (ESV)- “And the Lord answered me: “Write the vision; make it plain on tablets, so he may run who reads it.”Haggai 2: 9 - “The glory of this latter house shall be greater than of the former, saith the Lord of hosts: and in this place will I give peace, saith the Lord of hosts.” Pray for the successful organization and resounding impact of the upcoming Healing Streams Live Healing Services with Pastor Chris.Declare that this edition will supersede all previous editions, as the Bible declares that the glory of the latter house will surpass the former. Also, pray that the program will be a reference point for participants worldwide in the days, months, and years to come in Jesus’ Name. Amen. */}
                  </p>
            </Flex>
            <LiveKitRoom serverUrl={tpc.serverUrl} token={tpc.accessToken}
                data-lk-theme="default"
                >
                <AudioConference />
            </LiveKitRoom>
        </PageWrapper>
    );
}


// const Participant = () => {
//     return (
//         <Flex className="flex flex-col items-center justify-center">
//             <Flex className="bg-slate-400 h-[50px] w-[50px] rounded-[50px] items-center justify-center mr-2 flex-shrink-0">
//                 <Text className="font-normal text-white font-3">FA</Text>
//             </Flex>
//             <Text className="text-color-2 font-3">Ruth</Text>
//         </Flex>
//     )
// }

// const ActionBtn = ({ icon, onPress, bgColor = "bg-color-3" }: { icon: ReactNode, onPress?: () => void, bgColor?: string }) => {
//     return (
//         <Flex className={`w-[40px] h-[40px] ${bgColor} items-center justify-center mr-3 rounded`} onClick={onPress}>
//             {icon}
//         </Flex>
//     )
// }

// const TpcRoom = () => {
//     const { AppButton } = useAppButton()
//     const { state } = useLocation()
//     const navigate = useNavigate()
//     const [tpc, setTpc] = useState({
//         name: "", time: "", area: "", prayer: ""
//     })
//     const { roomId } = useParams()

//     console.log(state);

//     useEffect(() => {
//         if (tpc.name) return;
//         if (state?.name && state?._id) {
//             setTpc(state)
//         } else {
//             getTpc(`${roomId}`).then(res => {
//                 setTpc(res.data.data)
//                 toast(res.data.message).success()
//             }).catch(err => {
//                 console.log(err)
//                 navigate("*")
//                 toast(err?.response?.data?.message).error()
//             })
//         }
//     }, [tpc.name])

//     return (
//         <PageWrapper showName={true} title={`${tpc.name}, TCP Prayer Room`} icon={true}>
//             <Flex className="flex-col mx-auto">
//                 <Flex className="w-[90%] 
//                 min-h-[200px]
//                  p-10 mx-auto shadow-md 
//                  backdrop-blur-md bg-color-1 
//                  rounded-[10px] flex-col mb-4">
//                     <h3 className="text-center text-color-2 my-2 font-2 text-[33px]">Prayer Point</h3>
//                     {/* /<h5 className="font-2 text-[22px] text-white font-medium">PRAY FOR THE ORGANIZATION AND IMPACT OF THE UPCOMING HEALING STREAMS LIVE HEALING SERVICES</h5> */}
//                     <p className="font-2 text-white text-[20px]">
//                         {tpc.prayer}
//                         {/* Habakkuk 2:2 (ESV)- “And the Lord answered me: “Write the vision; make it plain on tablets, so he may run who reads it.”Haggai 2: 9 - “The glory of this latter house shall be greater than of the former, saith the Lord of hosts: and in this place will I give peace, saith the Lord of hosts.” Pray for the successful organization and resounding impact of the upcoming Healing Streams Live Healing Services with Pastor Chris.Declare that this edition will supersede all previous editions, as the Bible declares that the glory of the latter house will surpass the former. Also, pray that the program will be a reference point for participants worldwide in the days, months, and years to come in Jesus’ Name. Amen. */}
//                     </p>
//                 </Flex>
//                 <Text className="text-center text-white font-3">Total Participants: 40</Text>

//                 <Flex className="flex flex-row mx-auto my-2">
//                     <div className="min-w-[300px] max-w-[500px] overflow-x-auto flex participants-list py-2">
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                         <Participant />
//                     </div>
//                 </Flex>

//                 <Flex className="flex flex-row mx-auto justify-around my-3">
//                     <ActionBtn icon={<BsChat size={20} color="black" />} bgColor="bg-slate-300" />
//                     <ActionBtn icon={<BsMicMute size={20} color="white" />} />
//                     <ActionBtn icon={<IoExit size={20} color="white" />} />
//                     <Text className="text-white font-3 text-[15px] my-auto">End Prayer</Text>
//                 </Flex>
//                 <br />
//             </Flex>
//         </PageWrapper>
//     );
// }


// const TpcRoom = () => {
//     const state = useLocation().state
//     const [tpc,setTpc] = useState({ name: "", time: "", area: "", prayer: "",roomUrl:""})
//     const {roomId} = useParams()
//     const navigate = useNavigate()
//     console.log(roomId);
//     useEffect(() => {
//                 // if (!tpc.name){
//                 if (state?.name && state?._id) {
//                     setTpc(state)
//                 } else {
//                     getTpc(roomId as string).then(res => {
//                         setTpc({...res.data.data})
//                         toast(res.data.message).success()
//                     }).catch(err => {
//                         console.log(err)
//                         navigate("*")
//                         toast(err?.response?.data?.message).error()
//                     })
//                 }
//             // }
//             }, [tpc.name])


// //             useEffect(()=>{
// // const element = document.querySelector(".initials-18dr") as HTMLElement
// // if(!element) return; 
// // element.innerHTML = "Fish" 

// //             },[])

// useEffect(()=>{
// const element = document.querySelector('.RoomHeader-1j7y') as HTMLHeadElement;
// if(element){
//     element.style.display = "none"
// }
// },[])
//     return ( 
//         <>
//         <iframe 
//     title={tpc.name} 
//         src={`${tpc.roomUrl}?cameraAccess=off`}
//         allow="camera; microphone; fullscreen; speaker; display-capture" 
//         style={{height: '700px',width: '100%'}}>
//         {/* <div style={{}}>
//             {tpc.roomUrl}
//         </div> */}

//         </iframe>

//         </>
//     );
// }






export default TpcRoom;