
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext, userToken } from '../../context/UserContext';
import { useLocation } from 'react-router-dom';

const PublicRoutes = ({ children }: { children: any }) => {
    const token = userToken()
    const { user } = useUserContext()

    const { search } = useLocation()
    const redirectUrl = new URLSearchParams(search).get("redirectUrl");

    if (
        // user?.name || 
        token) {
        return <Navigate to={redirectUrl ? redirectUrl : "/"} />
    }
    return children
}

export default PublicRoutes;