import {useEffect} from "react"
import { Flex, Select, Text, TextInput } from "@mantine/core";
import PageWrapper from "../components/PageWrapper";
import { inputStyles, toast } from "../utils/helpers";
import useAppButton from "../hooks/useAppButton";
import { useState } from "react";
import { register } from "../services/userService";
import { Action_Type, useUserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { Country, IState, State } from 'country-state-city';
import {AiOutlineEye,AiOutlineEyeInvisible} from "react-icons/ai"
import { DateInput } from "@mantine/dates";
import moment from "moment";


const Register = () => {
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        country: "",
        state:"",
        dob:""
    })

    const { setLoading, AppButton } = useAppButton()
    const { userDispatch } = useUserContext()
    const navigate = useNavigate()
    const [showPassword,setShowPassword] = useState(false)
    const [selectedCountryStates, setSelectedCountryStates] = useState<IState[]>([])
    const [dob, setDob] = useState<Date | null>(null);

    useEffect(() => {
        if (!user.country) return;
        const { isoCode } = JSON.parse(user.country)
        setSelectedCountryStates(State.getStatesOfCountry(isoCode))
    }, [user.country])

    const handleShowPassword = () => setShowPassword(!showPassword)

    const handleInputChnage = ({ target: { name, value } }: { target: { name: string, value: string } }) => {
        if (name === "country") {
            setUser({ ...user, [name]: JSON.stringify(JSON.parse(value)) });
        } else {
            setUser({ ...user, [name]: value });
        }
    };


    const handleDateInputChange = (value:any)=>{
        const date = new Date(value)
        const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
        const today = new Date(Date.now())
        const td = Math.abs(Number(today)-Number(date))
        const age = Math.floor(td/millisecondsPerYear)
        if(age < 13){
            toast('You must be 13 yrs and above!').error()
            setDob(null)
        }else{
            setDob(value)
            setUser({...user,dob:moment(date).format('DD/MM/YYYY')})
        }        }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        setLoading(true)
        register({...user,
            country:JSON.parse(user.country)?.name}).then((res) => {
            console.log(res.data)
            setLoading(false)
            toast(res.data.message).success()
            setUser({
                name: "",
                email: "",
                password: "",
                country: "",
                state:"",
                dob:""
            })
            if (userDispatch)
                userDispatch({
                    type: Action_Type.USER_PROFILE,
                    payload: res.data.data,
                })
            navigate("/")
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }

    return (
        <PageWrapper title="Create an account">
            <Flex className="flex-col mx-auto">
                <form onSubmit={handleSubmit}>
                    <TextInput
                        onChange={handleInputChnage}
                        name="name"
                        className="my-2 mx-auto "
                        placeholder="Name"
                        required
                        sx={{ input: inputStyles, width: 300 }} />
                    <TextInput
              
                    type="text"
                        onChange={handleInputChnage}
                        name="email"
                        className="my-2 mx-auto "
                        placeholder="Email"
                        required
                        sx={{ input: inputStyles, width: 300 }} />

<TextInput
      rightSection={!showPassword?<AiOutlineEye onClick={handleShowPassword} className="cursor-pointer"/>
      :<AiOutlineEyeInvisible  onClick={handleShowPassword} className="cursor-pointer"/>}
                    type={showPassword?"text":"password"}
                        onChange={handleInputChnage}
                        name="password"
                        className="my-2 mx-auto "
                        placeholder="Password"
                        required
                        sx={{ input: inputStyles, width: 300 }} />

                        <DateInput
                        value={dob}
                        sx={{input:inputStyles}}
              onChange={handleDateInputChange}
      placeholder="Date input"
    />
                        
                    <Select
                        onChange={(value: string) => setUser({ ...user, country: value })}
                        name="country"
                        required
                        searchable
                        value={user.country}
                        placeholder="Country"
                        sx={{ input: inputStyles, width: 300 }}
                        data={[...Country.getAllCountries().map(data => {
                            return { value: JSON.stringify({ isoCode: data.isoCode, name: data.name }), label: data.name }
                        })]}
                        className="my-2 mx-auto"
                    />
                     <Select
                                        required
                                        placeholder="State"
                                        searchable
                                        nothingFound="No options"
                                        clearable
                                        value={user.state}
                                        onChange={(value: string) => setUser({ ...user, state: value })}
                                        data={[...selectedCountryStates.map(data => {
                                            return { value: data?.name, label: data?.name }
                                        })]}
                                        sx={{ input: inputStyles, width: 300 }} />

                    <AppButton txt="Register" width="w-[300px]" btnColor="bg-color-3 text-white" btnType={2} />
                    {/* <Text
                        className="cursor-pointer"
                        onClick={() => navigate("/login")}>Already have an account, login here</Text> */}
                </form>
                <div className="text-center">
                    <div>
                        <Text
                            className="cursor-pointer"
                            onClick={() => navigate("/login")}
                            style={{ color: "white" }}
                        >
                            <span>Have an account? </span>
                            <span style={{ textDecoration: "underline" }}>Login Now</span>
                        </Text>
                    </div>
                </div>
            </Flex>

        </PageWrapper >
    );
}

export default Register;