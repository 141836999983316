/* eslint-disable no-restricted-globals */
import { ReactNode } from "react";
import { BackgroundImage, Container, Flex, Image, Text, Menu, UnstyledButton } from "@mantine/core";
import layerImg from "../images/background.png";
import logo from "../images/logo.png"
import { BsUpload } from "react-icons/bs";
import { Action_Type, useUserContext } from "../context/UserContext";
import { BiCreditCard, BiHeadphone, BiLogOutCircle, BiMoneyWithdraw } from "react-icons/bi";
import { IoAddSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom"
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FiHome } from "react-icons/fi"

const PageWrapper = ({ children,
    title = "TeeVolution Prayer Cell (TPC)",
    showName = false, icon }: { children: ReactNode, title?: string, showName?: boolean, icon?: ReactNode }) => {
    const { user, userDispatch } = useUserContext()
    const navigate = useNavigate()
    const handleNavigate = (path: string) => navigate(path)

    const handleLogout = () => {
        if (userDispatch)
            userDispatch({
                type: Action_Type.LOGOUT_USER,
            })
        handleNavigate("/login")
    }

    // console.log("splinter",user?.name?.split(" ").length)

    const renderProfile = (name?: string) => {
        if (!name) return null;
        const splited = name.toUpperCase().split(" ")
        if (splited.length === 1) {
            return `${splited[0][0]} ${splited[0][1]}`
        } else return `${splited[0][0]} ${splited[1][0]}`
    }


    const location = useLocation();
    // Get the previous route
    const previousRoute = location.state?.from || '/';
    console.log("previousRoute", previousRoute);
    return (
        <>
            {showName &&
                <>
                    <Flex className="absolute flex-row justify-between w-full sm:px-14 px-3 mt-11 mb-2 items-center">
                        <Text className="font-3 text-white">Hi {user && user.name}</Text>
                        <Menu shadow="md" width={150}>
                            <Menu.Target>
                                <Flex className="bg-slate-400 h-[50px] w-[50px] rounded-[50px] items-center justify-center cursor-pointer">
                                    <Text className="font-normal text-white font-3">

                                        {/* {`${user?.name?.split(" ")[0][0]} ${user?.name?.split(" ")[1][0]}`} */}
                                        {renderProfile(user?.name)}
                                    </Text>
                                </Flex>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item icon={<FiHome size={20} />} onClick={() => handleNavigate("/")}>Home</Menu.Item>
                                <Menu.Item icon={<IoAddSharp size={20} />} onClick={() => handleNavigate("/create-tpc")}>Create TPC</Menu.Item>
                                <Menu.Item icon={<BiHeadphone size={20} />} onClick={() => handleNavigate("/join-tpc")}>Join TPC</Menu.Item>
                                <Menu.Item icon={<BiCreditCard size={20} />} onClick={() => handleNavigate("/sponsorship")}>Sponsor Teevo</Menu.Item>
                                <Menu.Item icon={<BiLogOutCircle size={20} />} onClick={handleLogout}>Logout</Menu.Item>

                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                    {window.location.pathname !== "/" && <Flex className="absolute ml-3 sm:ml-14
       mt-[120px] flex-row items-center justify-between cursor-pointer" onClick={() => history.back()}>
                        <AiOutlineArrowLeft color="white" />
                        <UnstyledButton className="text-white ml-2">
                            Go back</UnstyledButton>
                    </Flex>}


                </>

            }

            <BackgroundImage src={layerImg}
            >

                <Flex direction={"column"}
                    align={'center'} mih={"100vh"}
                    p={0} m={0}
                >
                    <Container
                        // className="border"
                        mt={!showName ? 100 : 150}
                    // mt={100}
                    >


                        <Image src={logo} width={180} className="mx-auto" />

                        {icon && <Flex className="flex flex-row items-center mb-4 justify-center">
                            <Text className="text-white font-1 font-black text-[27px] mr-4">{title}</Text>
                            <BsUpload className="text-color-2 font-3" size={20} />
                        </Flex>}

                        {!icon && <Text className="text-white font-1 font-black text-[27px] text-center mb-4">{title}</Text>}
                        {children}
                    </Container>
                </Flex>
            </BackgroundImage >
        </>
    );
}

export default PageWrapper;